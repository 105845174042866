@include media-breakpoint-up(sm) {
	.digit {
		width: 50px;
		height: 50px;
		margin: 5px;
		text-align: center;
		font-size: 20px;
		color: transparent;
		box-shadow: 0px 2px 5px -2px #0d0d0d5e;
		text-shadow: 0 0 0 #040404bb;
		border-color: #dcdcdcfc;
		border-width: 1px;
		border-radius: 5px;
		border-style: solid;
		outline-color: #04040458;
		transition: 0.3s transform;
		transform: scale(1);

	}

	.currExibitColumn {
		&.gridView {

			.imgHolder {
				max-width: 203px;
			}
		}
	}

	.listHasColumns {
		column-count: 2;

		>li {
			page-break-inside: avoid;
		}
	}

	.collectionColumn {

		.imgHolder {
			height: auto;
		}
	}

	.saMap {
		height: 250px;
	}

	.newsletterAside {

		.form-group {
			width: auto;
		}

		.form-control {
			width: 220px;
		}

		.btn {
			display: inline-block;
			min-width: 150px;
		}
	}

	.ssArrowVi {
		margin-top: -81px;
		@include size(30px);

		&.slickNext {
			right: -50px;
		}

		&.slickPrev {
			left: -50px;
		}
	}

	.qlColumnsWrap {

		&.qlColumnsWrapII {

			.col {
				border-left-color: $border-color;
			}
		}

		.col {
			border-left: 1px solid rgba($white, 0.06);
		}
	}

	.qlColum {
		min-height: 272px;
	}

	.epcTimeStamp {
		float: none;
	}

	.eveProColumn {

		.imgHolder {
			width: 110px;
			float: none;
		}

		.descrWrap {
			width: auto;
		}
	}

	.lcbContList {

		>li {
			min-width: 50%;
		}
	}

	.ueEveColumn {

		&.gridView {

			.imgHolder {
				width: auto;
			}
		}

		.imgHolder {
			@include size(211px, initial);
		}
	}

	.vnFig {
		max-width: none;
	}

	.fasFrameWrap {
		height: auto;
		top: 0;
		right: 0;
		bottom: 0;
		width: calc(50% - 6px);
		position: absolute;

		&.fasFrameWrapII {
			height: auto;
			top: 0;
			right: 0;
			bottom: 0;
			width: 50%;
			position: absolute;
		}
	}

	.vtcBtnsWrap {

		.btn {
			width: 66%;
		}
	}

	.shopItemColumn {
		max-width: none;
	}

	.scPagerNav {
		font-size: 16px;
		line-height: (22/16);

		a {

			&:before {
				margin-top: -12px;
				@include size(30px);
			}
		}

		.scpnPrev {
			padding-left: 55px;
		}

		.scpnNext {
			padding-right: 55px;
		}
	}

	.historyList {

		time {
			width: 200px;
			font-size: 20px;
			line-height: (22/20);
		}
	}

	.logosList {
		max-width: none;

		li {
			min-height: 136px;
		}

		a {
			padding-left: 25px;
			padding-right: 25px;
		}
	}

	.contactFormWrap {

		.formCol6 {
			width: 50%;
		}
	}

	.orderPlacerBox {

		.btn {
			width: auto;
		}
	}

	.btnHelp {
		right: 0;
		top: 0;
		position: absolute;
	}

	.cardImage {
		max-width: 100%;
	}

	.loginBlock {

		.btnForget {
			font-size: inherit;
		}
	}

	.acwColLarge {
		max-width: 265px;
	}

	.acwColSmall {
		max-width: 153px;
	}

	.acwColII {
		margin-top: 80px;
	}

	.acwColIII {
		clear: left;
		margin-top: 80px;
	}

	.acwColIV {
		margin-top: 180px;
	}

	.acwColV {
		margin-top: 80px;
		clear: right;
	}

	.acwColVI {
		margin-top: 290px;
	}

	.ibtbtOpener {
		top: 21px;
	}

	.my-masonry-grid_column {
		padding-left: 30px;
	}

	.my-masonry-grid {
		margin-left: -30px;
		padding: 0;
	}

	.productContentBlock {
		.counter {
			margin-bottom: 0;
			height: auto;
		}
	}

	.auth-nav {
		.logo.logoVii {
			max-width: 180px;
		}
	}

	.custom-row-login {
		width: 600px;
	}

	.user-initials {
		p {
			margin: 0;
			word-break: break-all;
			font-size: 16px;
		}
	}

	.user-avatar {
		width: 40px;
		height: 40px;
	}

	.auth-form {
		.btn-secondary {
			font-size: 17px;
		}
	}

	.collection-parent {
		height: 400px;
	}

	.collection-img-container {
		height: 400px;
	}
}