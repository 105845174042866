input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
	-webkit-appearance: none;
	margin: 0;
}

input[type=number]
{
	-moz-appearance: textfield;
}

.quantity input {
	float: left;
	text-align: center;
	display: block;
	margin: 0;
	padding: 10px 30px 10px 3px;
	border-width: 1px;
	border-style: solid;
	font-size: $input-btn-font-size;
	line-height: $input-btn-line-height;
	@include size(79px, $input-height);
}

.quantity input:focus {
	outline: 0;
}

.quantity-nav {
	height: 100%;
}

.quantity-button {
	position: absolute;
	right: 1px;
	cursor: pointer;
	border-left: 1px solid $border-color;
	text-align: center;
	color: $gray-200;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include size(26px, 50%);

	&:before{
		display: block;
		content: '';
		border-style: solid;
		@include size(0);
	}
}

.quantity-button.quantity-up {
	top: 0;

	&:before{
		border-width: 0 4px 4px 4px;
		border-color: transparent transparent $gray-600 transparent;
	}
}

.quantity-button.quantity-down {
	bottom: 0;
	border-top: 1px solid $border-color;

	&:before{
		border-width: 4px 4px 0 4px;
		border-color: $gray-600 transparent transparent transparent;
	}
}