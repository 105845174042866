@font-face {
	font-family: "Flaticon";
	src: url("../fonts/Flaticon.woff") format("woff"),
	url("../fonts/Flaticon.ttf") format("truetype"),
	url("../fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../fonts/Flaticon.svg#Flaticon") format("svg");
	}
}

.fi:before{
	display: inline-block;
	font-family: "Flaticon";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

.flaticon-001-pottery:before { content: "\f100"; }
.flaticon-002-teapot:before { content: "\f101"; }
.flaticon-003-picture:before { content: "\f102"; }
.flaticon-004-statue:before { content: "\f103"; }
.flaticon-005-oven:before { content: "\f104"; }
.flaticon-006-column:before { content: "\f105"; }
.flaticon-007-clay-crafting:before { content: "\f106"; }
.flaticon-008-carving:before { content: "\f107"; }
.flaticon-009-artist:before { content: "\f108"; }
.flaticon-010-museum:before { content: "\f109"; }
.flaticon-011-pottery-1:before { content: "\f10a"; }
.flaticon-012-pottery-2:before { content: "\f10b"; }
.flaticon-013-knife:before { content: "\f10c"; }
.flaticon-014-painting:before { content: "\f10d"; }
.flaticon-015-tiles:before { content: "\f10e"; }
.flaticon-016-carving-1:before { content: "\f10f"; }
.flaticon-017-pot:before { content: "\f110"; }
.flaticon-018-vases:before { content: "\f111"; }
.flaticon-019-spatula:before { content: "\f112"; }
.flaticon-020-sculpture:before { content: "\f113"; }
.flaticon-021-pot-1:before { content: "\f114"; }
.flaticon-022-pottery-3:before { content: "\f115"; }
.flaticon-023-pottery-4:before { content: "\f116"; }
.flaticon-024-palette:before { content: "\f117"; }
.flaticon-025-eye:before { content: "\f118"; }
.flaticon-026-sponge:before { content: "\f119"; }
.flaticon-027-spray:before { content: "\f11a"; }
.flaticon-028-stand:before { content: "\f11b"; }
.flaticon-029-pencil:before { content: "\f11c"; }
.flaticon-030-statue-1:before { content: "\f11d"; }
.flaticon-031-dish:before { content: "\f11e"; }
.flaticon-032-pottery-5:before { content: "\f11f"; }
.flaticon-033-canvas:before { content: "\f120"; }
.flaticon-034-pencil-1:before { content: "\f121"; }
.flaticon-035-apron:before { content: "\f122"; }
.flaticon-036-crayon:before { content: "\f123"; }
.flaticon-037-tool:before { content: "\f124"; }
.flaticon-038-wire:before { content: "\f125"; }
.flaticon-039-sculpture-1:before { content: "\f126"; }
.flaticon-040-ceramics:before { content: "\f127"; }
.flaticon-041-wheel:before { content: "\f128"; }
.flaticon-042-rolling-pin:before { content: "\f129"; }
.flaticon-043-statue-2:before { content: "\f12a"; }
.flaticon-044-museum-1:before { content: "\f12b"; }
.flaticon-045-paint-roller:before { content: "\f12c"; }
.flaticon-046-brush:before { content: "\f12d"; }
.flaticon-047-drying:before { content: "\f12e"; }
.flaticon-048-mug:before { content: "\f12f"; }
.flaticon-049-pottery-6:before { content: "\f130"; }
.flaticon-050-paint-bucket:before { content: "\f131"; }

$font-Flaticon-001-pottery: "\f100";
$font-Flaticon-002-teapot: "\f101";
$font-Flaticon-003-picture: "\f102";
$font-Flaticon-004-statue: "\f103";
$font-Flaticon-005-oven: "\f104";
$font-Flaticon-006-column: "\f105";
$font-Flaticon-007-clay-crafting: "\f106";
$font-Flaticon-008-carving: "\f107";
$font-Flaticon-009-artist: "\f108";
$font-Flaticon-010-museum: "\f109";
$font-Flaticon-011-pottery-1: "\f10a";
$font-Flaticon-012-pottery-2: "\f10b";
$font-Flaticon-013-knife: "\f10c";
$font-Flaticon-014-painting: "\f10d";
$font-Flaticon-015-tiles: "\f10e";
$font-Flaticon-016-carving-1: "\f10f";
$font-Flaticon-017-pot: "\f110";
$font-Flaticon-018-vases: "\f111";
$font-Flaticon-019-spatula: "\f112";
$font-Flaticon-020-sculpture: "\f113";
$font-Flaticon-021-pot-1: "\f114";
$font-Flaticon-022-pottery-3: "\f115";
$font-Flaticon-023-pottery-4: "\f116";
$font-Flaticon-024-palette: "\f117";
$font-Flaticon-025-eye: "\f118";
$font-Flaticon-026-sponge: "\f119";
$font-Flaticon-027-spray: "\f11a";
$font-Flaticon-028-stand: "\f11b";
$font-Flaticon-029-pencil: "\f11c";
$font-Flaticon-030-statue-1: "\f11d";
$font-Flaticon-031-dish: "\f11e";
$font-Flaticon-032-pottery-5: "\f11f";
$font-Flaticon-033-canvas: "\f120";
$font-Flaticon-034-pencil-1: "\f121";
$font-Flaticon-035-apron: "\f122";
$font-Flaticon-036-crayon: "\f123";
$font-Flaticon-037-tool: "\f124";
$font-Flaticon-038-wire: "\f125";
$font-Flaticon-039-sculpture-1: "\f126";
$font-Flaticon-040-ceramics: "\f127";
$font-Flaticon-041-wheel: "\f128";
$font-Flaticon-042-rolling-pin: "\f129";
$font-Flaticon-043-statue-2: "\f12a";
$font-Flaticon-044-museum-1: "\f12b";
$font-Flaticon-045-paint-roller: "\f12c";
$font-Flaticon-046-brush: "\f12d";
$font-Flaticon-047-drying: "\f12e";
$font-Flaticon-048-mug: "\f12f";
$font-Flaticon-049-pottery-6: "\f130";
$font-Flaticon-050-paint-bucket: "\f131";