@keyframes icnScaleX {
	0%{
		transform: scale(1.02, 0.8);
	}

	15%{
		transform: scaleX(1);
	}

	15%{
		transform: scale(0.8, 1.02);
	}

	75%{
		transform: scale(1.02, 0.8);
	}

	100%{
		transform: scaleX(1);
	}
}

@keyframes imgAnimBounce {
	0%{
		transform: scale(1.01);
		opacity: 1;
	}

	15%{
		transform: scaleX(1);
		opacity: 0.8;
	}

	15%{
		transform: scale(0.99);
		opacity: 1;
	}

	75%{
		transform: scale(1.01);
		opacity: 0.8;
	}

	100%{
		transform: scaleX(1);
		opacity: 1;
	}
}

@keyframes lftDoubt {
	0%{
		transform: scaleX(0);
		opacity: 0;
		visibility: hidden;
	}

	100%{
		transform: scaleX(1);
		opacity: 1;
		visibility: visible;
	}
}