@include media-breakpoint-up(xlwd) {
    .h1Large {
        font-size: 60px;
        line-height: (60/60);
    }

    .hmFullLinkingNav {
        right: 40px;
    }

    .auth-nav .logo.logoVii {
        max-width: 210px;
    }

    .user-initials {
        p {
            font-size: 17px;
        }
    }

    .user-avatar {
        width: 48px;
        height: 48px;
    }

    .main-title {
        font-size: 40px;
    }

    .auth-form {
        .btn-secondary {
            font-size: 19px;
        }
    }

    .modal-body {
        .hdSearchClose {
            font-size: 35px;
        }

        padding: 110px 50px;
        max-width: 800px;

        h3 {
            font-size: 36px;
            margin-bottom: 45px;
        }

        .item-thumb {
            width: 125px;
            height: 125px;
            object-fit: cover;

            img {
                width: 125px;
                height: 125px;
                object-fit: contain;
                display: flex;
                align-items: center;
            }
        }

        .item-short-description {
            h5 {
                font-size: 27px;
            }

            span {
                font-size: 25px;
            }
        }

        label {
            font-size: 24px;
        }

        input {
            font-size: 23px;
        }
    }
}