@font-face {
	font-family: "Flaticon";
	src: url("./fonts/Flaticon.woff") format("woff"), url("./fonts/Flaticon.ttf") format("truetype"), url("./fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
	@font-face {
		font-family: "Flaticon";
		/* src: url("./Flaticon.svg#Flaticon") format("svg"); */
		src: url("./fonts/Flaticon.svg#Flaticon") format("svg");

	}
}

.fi:before {
	display: inline-block;
	font-family: "Flaticon";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	line-height: 1;
	text-decoration: inherit;
	text-rendering: optimizeLegibility;
	text-transform: none;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

.flaticon-001-pottery:before {
	content: "\f100";
}

.flaticon-002-teapot:before {
	content: "\f101";
}

.flaticon-003-picture:before {
	content: "\f102";
}

.flaticon-004-statue:before {
	content: "\f103";
}

.flaticon-005-oven:before {
	content: "\f104";
}

.flaticon-006-column:before {
	content: "\f105";
}

.flaticon-007-clay-crafting:before {
	content: "\f106";
}

.flaticon-008-carving:before {
	content: "\f107";
}

.flaticon-009-artist:before {
	content: "\f108";
}

.flaticon-010-museum:before {
	content: "\f109";
}

.flaticon-011-pottery-1:before {
	content: "\f10a";
}

.flaticon-012-pottery-2:before {
	content: "\f10b";
}

.flaticon-013-knife:before {
	content: "\f10c";
}

.flaticon-014-painting:before {
	content: "\f10d";
}

.flaticon-015-tiles:before {
	content: "\f10e";
}

.flaticon-016-carving-1:before {
	content: "\f10f";
}

.flaticon-017-pot:before {
	content: "\f110";
}

.flaticon-018-vases:before {
	content: "\f111";
}

.flaticon-019-spatula:before {
	content: "\f112";
}

.flaticon-020-sculpture:before {
	content: "\f113";
}

.flaticon-021-pot-1:before {
	content: "\f114";
}

.flaticon-022-pottery-3:before {
	content: "\f115";
}

.flaticon-023-pottery-4:before {
	content: "\f116";
}

.flaticon-024-palette:before {
	content: "\f117";
}

.flaticon-025-eye:before {
	content: "\f118";
}

.flaticon-026-sponge:before {
	content: "\f119";
}

.flaticon-027-spray:before {
	content: "\f11a";
}

.flaticon-028-stand:before {
	content: "\f11b";
}

.flaticon-029-pencil:before {
	content: "\f11c";
}

.flaticon-030-statue-1:before {
	content: "\f11d";
}

.flaticon-031-dish:before {
	content: "\f11e";
}

.flaticon-032-pottery-5:before {
	content: "\f11f";
}

.flaticon-033-canvas:before {
	content: "\f120";
}

.flaticon-034-pencil-1:before {
	content: "\f121";
}

.flaticon-035-apron:before {
	content: "\f122";
}

.flaticon-036-crayon:before {
	content: "\f123";
}

.flaticon-037-tool:before {
	content: "\f124";
}

.flaticon-038-wire:before {
	content: "\f125";
}

.flaticon-039-sculpture-1:before {
	content: "\f126";
}

.flaticon-040-ceramics:before {
	content: "\f127";
}

.flaticon-041-wheel:before {
	content: "\f128";
}

.flaticon-042-rolling-pin:before {
	content: "\f129";
}

.flaticon-043-statue-2:before {
	content: "\f12a";
}

.flaticon-044-museum-1:before {
	content: "\f12b";
}

.flaticon-045-paint-roller:before {
	content: "\f12c";
}

.flaticon-046-brush:before {
	content: "\f12d";
}

.flaticon-047-drying:before {
	content: "\f12e";
}

.flaticon-048-mug:before {
	content: "\f12f";
}

.flaticon-049-pottery-6:before {
	content: "\f130";
}

.flaticon-050-paint-bucket:before {
	content: "\f131";
}

@font-face {
	font-family: "icomoon";
	src: url("./fonts/icomoon.ttf?h7kkm8") format("truetype"), url("./fonts/icomoon.woff?h7kkm8") format("woff"), url("./fonts/icomoon.svg?h7kkm8#icomoon") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icomoon-"],
[class*=" icomoon-"] {
	font-family: "icomoon" !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icomoon-user:before {
	content: "\e90c";
}

.icomoon-layers:before {
	content: "\e90d";
}

.icomoon-breifcase:before {
	content: "\e90e";
}

.icomoon-smiley:before {
	content: "\e90f";
}

.icomoon-share:before {
	content: "\e909";
}

.icomoon-download:before {
	content: "\e90a";
}

.icomoon-resize:before {
	content: "\e90b";
}

.icomoon-UpCircle:before {
	content: "\e905";
}

.icomoon-leftCircle:before {
	content: "\e906";
}

.icomoon-downCircle:before {
	content: "\e907";
}

.icomoon-rightCircle:before {
	content: "\e908";
}

.icomoon-arrowCircleDown:before {
	content: "\e900";
}

.icomoon-arrowCircleRight:before {
	content: "\e901";
}

.icomoon-arrowCircleUp:before {
	content: "\e902";
}

.icomoon-arrowCircleLeft:before {
	content: "\e903";
}

.icomoon-search:before {
	content: "\e904";
}

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
}

.slick-list:focus {
	outline: none;
}

.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.slick-track:before,
.slick-track:after {
	content: "";
	display: table;
}

.slick-track:after {
	clear: both;
}

.slick-loading .slick-track {
	visibility: hidden;
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
}

[dir="rtl"] .slick-slide {
	float: right;
}

.slick-initialized .slick-slide {
	display: block;
}

.slick-loading .slick-slide {
	visibility: hidden;
}

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
	display: none;
}

// Increment Decrement Counter ( cart page )
.counter-handlers {
	display: flex;
	flex-direction: column;
	gap: 0;
	height: 100%;
}

.handler-btn {
	border: 0;
	outline: 0;
	background-color: #fff;
	border: 0;
	width: 26px;
	height: 100%;
	line-height: 0;
	text-align: center;
	padding: 0;
	border-left: 1px solid #e8e8e8;
}

.-prepend.prependers:nth-child(1) {
	border-bottom: 1px solid #e8e8e8;
}

.-prepend.prependers:nth-child(2) {
	border-top: 1px solid #e8e8e8;
}

.handler-btn svg {
	color: #555;
	height: 11px;
}

.handler-btn:focus {
	outline: 0;
}

.counter {
	display: flex;
	text-align: center;
	margin: 0;
	border-width: 1px;
	border-style: solid;
	line-height: 1.2352941176;
	font-size: 16px;
	padding: 0px 0px 0px 3px;
	width: 70px;
	height: auto;
	align-items: center;
}

.counter input {
	height: auto;
	padding: 0 10px;
}

.prependers {
	line-height: 0;
	background-color: #fff;
	flex-grow: 1;
}

// Select dropdowns (cart page)
.chosenSelect {
	border: 0;
	font-size: 15px;
	padding: 9px 0 9px 14px;
	background-color: #fff;
	border: 1px solid #e8e8e8;
	margin-bottom: 10px;
}

.carterTableWrap {
	.handler-btn {
		width: 15px;
		background-color: #e8e8e8;
	}

	.counter-handlers {
		gap: 3px;
		height: 100%;
		margin: 3px 0px;
		margin-right: 3px;
	}

	.-prepend.prependers:nth-child(1) button {
		padding-top: 3px;
	}

	.-prepend.prependers:nth-child(2) button {
		padding-bottom: 3px !important;
		margin-bottom: 1px;
	}
}

.carterWrap {
	gap: 20px;
}

.nav-item {
	a {
		color: #444 !important;
	}

	&.active,
	&.hover {
		a {
			color: #c1b696 !important;
		}
	}
}

.btn-secondary {
	&.btn-small {
		color: #fff;
		background: rgb(37, 19, 219);
		background: $gradient-btn-bg;
		box-shadow: none;
		font-size: 12px;
		padding: 7px 12px;
		min-width: 50px !important;
		width: max-content;
		border: 0;
		background-size: 100% 100%;
		moz-transition: all .4s ease-in-out;
		-o-transition: all .4s ease-in-out;
		-webkit-transition: all .4s ease-in-out;
		transition: all .4s ease-in-out;
	}

	background: #2513db;
	background-image: $gradient-btn-bg;
	border: 0;
	background-size: 200% auto;
	moz-transition: all .4s ease-in-out;
	-o-transition: all .4s ease-in-out;
	-webkit-transition: all .4s ease-in-out;
	transition: all .4s ease-in-out;

	&:hover {
		border: 0;
		background-position: right center;
	}
}

@media screen and (min-width: 992px) {
	.pageMainTitle {
		margin-top: 5rem;
	}
}

.headerFixer {
	transition: all 0.3s ease !important;

	&.headerFixerII {

		&.fixedPosition {
			background-color: $secondary;
		}
	}

	&.headerFixerIII {

		&.fixedPosition {
			background-color: transparent;
		}
	}

	&.fixedPosition {
		background-color: $white;
		box-shadow: 0 5px 5px -5px rgba($black, 0.2);
		position: fixed;
		left: 0;
		right: 0;
	}
}

// cutom file input/image upload field
#file-upload::-webkit-file-upload-button {
	visibility: hidden;
	user-select: none;
}

#file-upload {
	display: none;
	user-select: none;
}

.image-uploader {
	position: relative;

	label {
		position: absolute;
		left: 1%;
		top: 50%;
		transform: translateY(-50%);
	}
}

// Withdraw page styles
.fancy-heading {
	text-align: center;
	color: #555;
	font-size: 18px;
}

// user avatar collection page
.user-email {
	display: flex;
	gap: 8px;
	align-items: center;
}

.user-initials {
	h5 {
		margin: 0;
		font-weight: bold;
	}

	p {
		margin: 0;
		font-size: 15px;
		word-break: break-all;
		font-size: 13px;
	}
}

.user-avatar {
	width: 35px;
	height: 35px;
	object-fit: cover;
	border-radius: 100%;
}

// country flag
.flag * {
	line-height: 1;
}

.flag {
	display: flex;
	align-items: center;

}

// Table custom css
.apDataTable.apDataTableII td:last-child,
.apDataTable.apDataTableII td:first-child {
	font-size: 15px;
}


// Error
.error {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	flex-direction: column;

	h1 {
		font-weight: bold;
		font-size: 65px;
	}
}


// Modal
.flexbox {
	display: flex;
	align-items: center;
	height: 100%;
}

.modal-body {
	background-color: #fff;
	width: 100%;
	margin: auto;
	padding: 40px 30px;
	max-width: 600px;
}

.item-thumbnail {
	display: flex;
	align-items: center;
	gap: 10px;
}

.item-thumb {
	width: 55px;
	height: 55px;
	object-fit: cover;
	display: flex;
	align-items: center;
}

.wallet-address {
	word-break: break-word;
}

// Art details page custom css
.singleProductSlider {
	width: 100%;
	height: 100%;
}

.singleProductSlider img {
	max-height: 600px;
	object-fit: contain;
}

.custom-row-login {
	border: 1px solid #80808057;
	padding: 85px;
	width: 660px;
	margin: auto;
	padding: 100px 45px;
	border-radius: 6px;
	box-shadow: 0 0 10px 0px rgb(0 0 0 / 9%);
	margin-top: 50px;
}

.custom-row-login {
	width: 90%;
	padding: 70px 5px;
	margin-top: 0;
}

.auth-form {
	.btn-secondary {
		font-size: 15px;
	}
}

.auth-form label strong {
	font-size: 17px;
}

.auth-form .formControlMedium {
	font-size: 17px;
}

.otp {
	position: relative;

	span {
		position: absolute;
		right: 13px;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		color: #370fda;
		padding: 0 10px;
		border-radius: 6px;
		font-weight: 500;
		cursor: pointer;
		text-decoration: underline;
		transition: all 300ms ease-in-out;

		&:hover {
			color: #6d02d4;
		}
	}
}

.otp span {
	font-size: 14px;
}

.form-title {
	font-size: 32px;
	text-align: center;
	text-transform: uppercase;
}

.form-title {
	font-size: 26px;
}

body * {
	font-family: 'Archivo', sans-serif;
}

.auth-nav {
	.logo.logoVii {
		max-width: 145px;
	}
}

.main-title {
	font-size: 35px;
}

.modal-body .hdSearchClose.close-modal {
	width: 25px;
	height: 25px;
	background: transparent;
	border-radius: 100%;
	color: #333;
	line-height: 10;
	padding-bottom: 4px;
	font-size: 20px;
	transition: all 300ms ease-in-out;
	border: 2px solid #333;

	&:hover {
		background: #333;
		color: #fff;
	}
}


// CUSTOM IMAGE HOLDER CONTAINER FOR COLLECTION DETAILS PAGE
.collection-parent {
	width: 600px;
	max-width: 100%;
	height: 320px;
	display: inline-block;
}

.collection-img-container {
	width: 600px;
	max-width: 100%;
	height: 320px;
	display: flex;
	flex-direction: column;

}

.collection-img-container>.middle {
	flex: 0 1 100%;
	max-height: 100%;
	max-width: 100%;
	display: block;
	object-fit: contain;
	border: 1px solid #dededecc;
	border-radius: 4px;
	padding: 10px 0;
}

.collection-img-container>.marge {
	flex: auto;
	background: blue;
	font-size: 6px;
}

div#inputs {
	display: flex;
	align-items: start;
	justify-content: space-between;
	flex-wrap: wrap;
}

.digit {
	width: 30px;
	height: 30px;
	margin: 5px;
	text-align: center;
	font-size: 14px;
	color: transparent;
	box-shadow: 0px 2px 5px -2px rgba(13, 13, 13, 0.368627451);
	text-shadow: 0 0 0 rgba(4, 4, 4, 0.7333333333);
	border-color: rgba(220, 220, 220, 0.9882352941);
	border-width: 1px;
	border-radius: 5px;
	border-style: solid;
	outline-color: rgba(4, 4, 4, 0.3450980392);
	transition: 0.3s transform;
	transform: scale(1);

	&:focus-visible {
		border: 0;
	}
}


.digit:focus {
	transform: scale(1.05);
	transition: 0.2s;
}

.flex-buttons {
	display: flex;
	align-items: start;
	flex-wrap: wrap;
	gap: 18px;

	button {
		&.btn-secondary {
			font-size: 16px;
		}
	}
}