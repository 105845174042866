@include media-breakpoint-up(xxl) {
    .auth-nav .logo.logoVii {
        max-width: 250px;
    }

    .prDecriptionWrap {
        .h2Medium {
            font-size: 43px;
            line-height: 1.25;
        }

        .reViewWrap {
            font-size: 23px;
        }

        .flag {
            span {
                font-size: 23px;
            }

            img {
                width: 30px;
                height: 30px;
            }
        }

        p {
            &.item-detail-description {
                font-size: 20px;
            }
        }

        .apDataTable.apDataTableII td:first-child {
            font-size: 18px;
            width: 22%;
        }

        .apDataTable.apDataTableII td:last-child {
            font-size: 16px;
        }

        .btn-secondary {
            font-size: 19px;
        }
    }

    .modal-body {
        .hdSearchClose {
            font-size: 40px;
        }

        padding: 110px 50px;
        max-width: 800px;

        h3 {
            font-size: 36px;
            margin-bottom: 45px;
        }

        .item-thumb {
            width: 125px;
            height: 125px;
            object-fit: cover;

            img {
                width: 125px;
                height: 125px;
                object-fit: contain;
                display: flex;
                align-items: center;
            }
        }

        .item-short-description {
            h5 {
                font-size: 27px;
            }

            span {
                font-size: 25px;
            }
        }

        label {
            font-size: 24px;
        }

        input {
            font-size: 23px;
        }
    }

}